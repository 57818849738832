<script setup>
import Layout from "../../layouts/main.vue";
import { ref, reactive, onMounted, watch } from "vue";

// ngôn ngữ -> bắt buộc
import { useRouter, useRoute } from "vue-router";
import Data from "./dataFormTableScreen.js";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import Swal from "sweetalert2";
import toastr from "toastr";
import Validate from "../../service/ValidService";
import { FormInstance } from "element-plus";
import CardItemConfig from "@/components/CardItemsConfig/CardItemsConfig";
import { useI18n } from "vue-i18n";
import { Filter } from "mushroomjs";
import LanguageService from "../../service/LanguageService";
import { langWeb } from "../../stores/lang";
const { t } = useI18n();
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(Data.tableRules));
const dataTables = reactive({ value: [] });
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const openDialogForm = ref(false);
const loadingBtn = ref(false);
const actionDialog = ref("");
const idCard = ref("");
const cardItem = ref({});
const typeTableScreen = ref("");
const dataCardItemConfig = reactive({
  visible: false,
  type: "update",
  step: 0,
});
const dataChartConfigRender = ref();
const dataUpdateCardItem = ref(null);
const renderCardItemConfig = ref(0);
let loading = ref(false);
const validateFlag = ref(false);
const pageSizeDefault = ref(10);
const listType = [
  {
    value: "probe",
    label: "Probe",
  },
  {
    value: "bras",
    label: "Bras",
  },
];

const ruleFormRef = ref(FormInstance);
const rulesForm = reactive({
  name: [Validate.required, Validate.checkMaxLength(50)],
  type: [Validate.required],
});

const getListTableScreen = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "TableScreen",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});

  try {
    const response = await mushroom.table_screen.listAsync(dataFilter, {
      cacheAge: 1,
    });
    if (response.result) {
      loading.value = false;
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

const getTableScreenById = async (id) => {
  if (!id) return;
  try {
    const response = await mushroom.table_screen.findByIdAsync({
      id: id,
    });
    if (response.result) dataForm.value = response.result;
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  getListTableScreen();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  getListTableScreen();
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = () => {
  tableRules.dataSearch.value["name"]
    ? (tableRules.dataSearch.value["name"] =
        tableRules.dataSearch.value["name"].trim())
    : (tableRules.dataSearch.value["name"] = "");

  tableRules.dataSearch.value["type"]
    ? (tableRules.dataSearch.value["type"] =
        tableRules.dataSearch.value["type"].trim())
    : (tableRules.dataSearch.value["type"] = "");

  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  getListTableScreen();
};

const getCardById = async (tableId) => {
  if (!tableId) return;
  try {
    const response = await mushroom.card.listAsync({
      filters: Filter.eq("table_screen_id", tableId).build(),
    });
    if (response.result?.length > 0) {
      idCard.value = response.result[0]?.id;
      await getCardItemById(idCard.value);
    } else toastr.warning(t("t_no_card_table_screen"));
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const getCardItemById = async (cardId) => {
  if (!cardId) return;
  try {
    const response = await mushroom.card_item.listAsync({
      filters: Filter.eq("card_id", cardId).build(),
    });
    if (response.result?.length > 0) {
      cardItem.value = {
        id: response.result[0]?.id,
        title: response.result[0]?.title,
      };
      dataCardItemConfig.visible = true;
      dataCardItemConfig.step = 2;
      dataCardItemConfig.type = "update";
      // renderCardItemConfig.value += 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const fn_Add = () => {
  if (ruleFormRef.value) ruleFormRef.value.resetFields();
  actionDialog.value = "create";
  openDialogForm.value = true;
};

const fn_handle = async (type, scope, row) => {
  if (type === "view") {
    typeTableScreen.value = row.type;
    await getCardById(row.id);
  }
  if (type === "update") {
    if (ruleFormRef.value) ruleFormRef.value.resetFields();
    actionDialog.value = "edit";
    openDialogForm.value = true;
    await getTableScreenById(row.id);
  }
  if (type === "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_record_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseGroup = await mushroom.table_screen.deleteAsync({
            id: row.id, // required
          });
          if (responseGroup.result) {
            toastr.success(t("t_record_delete_success"));
            await getListTableScreen();
          }
        } catch (e) {
          MethodService.showError(e.code);
        }
      }
    });
  }
};

const closeDialogCardItemsConfig = () => {
  dataCardItemConfig.visible = false;
  dataCardItemConfig.step = "";
};

const updateNewCardItems = async (data) => {
  data.table.format.forEach((item) => {
    if (!item?.showCellLink) item.cellLink = null;
    else {
      if (!item.cellLink.defaultLink?.formatString)
        item.cellLink.defaultLink = null;
    }
  });
  dataUpdateCardItem.value = data;
  const card_item_object = {
    id: cardItem.value.id,
    card_id: data.card_id,
    title: data.title,
    general: {
      header: {
        is_hidden: data.general.header.is_hidden,
        color: data.general.header.color,
        backgroundColor: data.general.header.backgroundColor,
      },
    },
    table_config_id: data.table_config_id,
    table: {
      hasNo: data.table.hasNo,
      isPaging: data.table.isPaging,
      format: data.table.format,
    },
    sql: data.sql,
    sql_table_formats:
      data.sql_table_formats.length > 0
        ? data.sql_table_formats?.split("\n")
        : [],
    settings: {
      add_start_days: data.settings?.add_start_days ?? null,
      add_end_days: data.settings?.add_end_days ?? null,
    },
  };
  try {
    const response = await mushroom.card_item.partialUpdateAsync(
      card_item_object
    );
    if (response.result == cardItem.value.id) {
      toastr.success(t("toastr_update_success"));
    } else toastr.warning(t("toastr_not_change_warning"));
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    dataCardItemConfig.visible = false;
  }
};

const dataChartConfig = (data) => (dataChartConfigRender.value = data);

const addNewTable = async (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (!valid) {
      loadingBtn.value = false;
      return;
    }
    const table_screen_object = {
      name: dataForm.value.name,
      type: dataForm.value.type,
    };
    try {
      const newId = await mushroom.table_screen.createAsync(
        table_screen_object
      );
      if (newId) {
        toastr.success(t("toastr_add_success"));
      }
    } catch (error) {
      if (error.meta.field === "$unique_key") {
        toastr.error(t("toastr_had_name"));
      } else toastr.error(t("toastr_error"));
    } finally {
      openDialogForm.value = false;
      setTimeout(async () => {
        await getListTableScreen();
        loadingBtn.value = false;
        formEl.resetFields();
      }, 1000);
    }
  });
};

const updateTable = async (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (!valid) {
      loadingBtn.value = false;
      return;
    }
    const table_screen_object = {
      id: dataForm.value.id,
      name: dataForm.value.name,
      type: dataForm.value.type,
    };
    try {
      const response = await mushroom.table_screen.partialUpdateAsync(
        table_screen_object
      );
      if (response.result == dataForm.value.id) {
        toastr.success(t("toastr_update_success"));
      } else toastr.warning(t("toastr_not_change_warning"));
    } catch (error) {
      if (error.meta.field === "$unique_key") {
        toastr.error(t("toastr_had_name"));
      } else toastr.error(t("toastr_error"));
    } finally {
      openDialogForm.value = false;
      setTimeout(() => {
        getListTableScreen();
        loadingBtn.value = false;
        formEl.resetFields();
      }, 1000);
    }
  });
};

const fn_hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
  pageSizeDefault.value = tableRules.limit;
  if (route.query.offset && route.query.limit) {
    tableRules.offset = Number(route.query.offset);
    tableRules.page = Number(route.query.offset) / route.query.limit + 1;
  }
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  fn_hashUrl();
  await getListTableScreen();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">
          {{ $t("t_table_screen") }}
        </h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button class="btn btn-secondary btn-border" @click="fn_Add()">
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  @submit.prevent
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t_table_screen_name')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="$t('t_table_screen_name')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-type-probe-bras')">
                        <el-select
                          v-model="tableRules.dataSearch.value['type']"
                          filterable
                          clearable
                          :placeholder="$t('t-type-probe-bras')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in listType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem()"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              background
              layout="total, sizes, prev, pager, next, jumper"
            />
          </div>
          <el-table
            size="small"
            style="width: 100%"
            max-height="550"
            :data="dataTables.value"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="100"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('t_table_screen_name')"
              min-width="100"
              align="start"
            />
            <el-table-column
              :label="$t('t-type-probe-bras')"
              min-width="100"
              align="start"
            >
              <template #default="scope">
                <span
                  v-if="scope.row.type === 'probe'"
                  class="badge badge-soft-success text-uppercase"
                  >Probe</span
                >
                <span
                  v-if="scope.row.type === 'bras'"
                  class="badge badge-soft-info text-uppercase"
                  >Bras</span
                >
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="200px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    :content="$t('t-config')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      @click="fn_handle('view', scope.$index, scope.row)"
                    >
                      <i class="ri-settings-4-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('action_option')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('action_delete')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>

      <el-dialog
        v-model="openDialogForm"
        :title="
          actionDialog === 'create'
            ? $t('t_table_screen_add')
            : $t('t_table_screen_edit')
        "
        width="40%"
        :close-on-click-modal="false"
        align-center
      >
        <div class="d-flex justify-content-around mb-3">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            :rules="rulesForm"
            label-position="top"
            class="form-card-items"
            style="width: 100%"
          >
            <el-form-item :label="$t('t_table_screen_name')" prop="name">
              <el-input
                type="text"
                :placeholder="$t('t-title-card-item')"
                v-model="dataForm.value.name"
                @change="dataForm.value.name = dataForm.value.name.trim()"
              />
            </el-form-item>
            <el-form-item :label="$t('t-type-probe-bras')" prop="type">
              <el-select
                v-model="dataForm.value.type"
                filterable
                clearable
                :placeholder="$t('t-type-probe-bras')"
                :disabled="actionDialog !== 'create'"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <div class="text-center">
              <el-button
                v-if="actionDialog === 'create'"
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewTable(ruleFormRef)"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateTable(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>

      <card-item-config
        :idCardItem="cardItem.id"
        :cardIdToCardItemsConfig="idCard"
        :cardTitleToCardItemsConfig="cardItem.title"
        :showCardItemConfig="dataCardItemConfig.visible"
        :stepNumberForCardItem="dataCardItemConfig.step"
        :typeShowCardItemConfig="dataCardItemConfig.type"
        :typeTableScreen="typeTableScreen"
        @closeDialogCardItemsConfig="closeDialogCardItemsConfig"
        @updateNewCardItems="updateNewCardItems"
        @dataChartConfig="dataChartConfig"
        :key="renderCardItemConfig"
      ></card-item-config>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
:deep .el-overlay {
  z-index: 1002 !important;
}
</style>
