<template>
  <el-dialog
    v-model="centerDialogVisible"
    :title="
      props.typeShowCardItemConfig == 'create'
        ? $t('t-add-chart', { msg: props.cardTitleToCardItemsConfig })
        : $t('t-options-chart', { msg: titleCardItem })
    "
    width="70%"
    :close-on-click-modal="false"
    :before-close="beforeCloseDialog"
    align-center
  >
    <div class="d-flex justify-content-around mb-3">
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center">
          <i
            class="ri-check-line span-step"
            :class="{ passStep: stepNumber > 1 }"
            v-if="stepNumber > 1"
          ></i>
          <span class="span-step" :class="{ passStep: stepNumber == 1 }" v-else
            >1</span
          >
        </div>
        <div class="">{{ $t("t_select_chart_type") }}</div>
      </div>
      <div class="line"></div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center">
          <i
            class="ri-check-line span-step"
            :class="{ passStep: stepNumber > 2 }"
            v-if="stepNumber > 2"
          ></i>
          <span class="span-step" :class="{ passStep: stepNumber == 2 }" v-else
            >2</span
          >
        </div>
        <div
          v-if="
            DataCardItems.value.type === 'table' ||
            DataCardItems.value.type === 'table-screen'
          "
        >
          {{ $t("t_config_table_column") }}
        </div>
        <div v-else>{{ $t("t_interface_config") }}</div>
      </div>
      <div class="line"></div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center">
          <i
            class="ri-check-line span-step"
            :class="{ passStep: stepNumber > 3 }"
            v-if="stepNumber > 3"
          ></i>
          <span class="span-step" :class="{ passStep: stepNumber > 2 }" v-else
            >3</span
          >
        </div>
        <div class="">{{ $t("t_data_config") }}</div>
      </div>
    </div>
    <el-form
      ref="ruleFormRef"
      :model="DataCardItems.value"
      :rules="rulesForm"
      label-width="150px"
      label-position="top"
      class="form-card-items"
    >
      <div v-if="stepNumber == 1">
        <el-form-item>
          <div
            class="select-card-item"
            v-if="DataCardItems.value.type == 'copy'"
          >
            <div class="select-card-item__select">
              <div class="select-card-item__select-item">
                <el-form-item :label="$t('t_select_dashboard')">
                  <el-select
                    filterable
                    v-model="nameDashboard"
                    :placeholder="$t('t_select_dashboard')"
                    @change="getListDashboard"
                  >
                    <el-option
                      v-for="item in DataForm.listDashboards"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="select-card-item__select-item">
                <el-form-item :label="$t('t_select_service_dashboard')">
                  <el-select
                    filterable
                    v-model="nameDashboardService"
                    :placeholder="$t('t_select_service_dashboard')"
                    @change="getListDashboardService"
                    :disabled="disableSelectListService"
                  >
                    <el-option
                      v-for="item in listService"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="select-card-item__select-item">
                <el-form-item :label="$t('t_select_card')">
                  <el-select
                    filterable
                    v-model="nameCard"
                    :placeholder="$t('t_select_card')"
                    @change="getListCardItems"
                  >
                    <el-option
                      v-for="item in listCardItems"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="select-card-item__item">
              <el-scrollbar height="286px">
                <div v-if="loadingGetListCardItems" class="card-item__status">
                  <i
                    style="font-size: 30px; opacity: 0.5; color: #0f6a33"
                    class="bx bx-loader-alt loading-icon"
                  ></i>
                  <p>{{ $t("t-loading-get-data") }}</p>
                </div>
                <div v-else>
                  <div v-if="cardItemsBlock.length > 0" class="card-item">
                    <div
                      class="card-item__block"
                      v-for="item in cardItemsBlock"
                      :key="item.id"
                      @click="clickCardItemBlock(item)"
                      :id="item.id"
                    >
                      <div>
                        <img
                          :src="linkImg(item.type)"
                          height="50"
                          width="50"
                          alt=""
                        />
                      </div>
                      <div class="card-item__box">
                        <div class="card-item__title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-item__status" v-else>
                    <i
                      style="font-size: 30px; opacity: 0.5; color: #e0ba00"
                      class="ri-information-line"
                    ></i>
                    <p>{{ $t("t-no-card-item") }}</p>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
          <el-radio-group v-model="DataCardItems.value.type" v-else>
            <el-scrollbar height="380px">
              <b-row class="chart-group w-100">
                <b-col md="4" class="text-center"
                  ><el-radio-button label="table">
                    <img
                      src="../../assets/images/table.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>table</span>
                  </el-radio-button>
                </b-col>
              </b-row>
            </el-scrollbar>
          </el-radio-group>
        </el-form-item>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            v-if="DataCardItems.value.type == 'copy'"
            @click="DataCardItems.value.type = ''"
          >
            {{ $t("t-back") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            v-if="DataCardItems.value.type == 'copy'"
            :disabled="
              cardItemsBlock.length == 0 || countClickCardItemBlock == 0
            "
            @click="copyCardItemNext()"
          >
            {{ $t("t-copy-card-item") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            @click="stepNumber += 1"
            :disabled="!DataCardItems.value.type"
            v-else
          >
            {{ $t("t-next") }}
          </button>
        </div>
      </div>

      <div v-if="stepNumber == 2">
        <div v-show="props.typeShowCardItemConfig === 'update'">
          <render-icon-chart
            :infoChartType="DataCardItems.value.type"
          ></render-icon-chart>
        </div>

        <el-form-item
          :label="$t('t-title-card-item')"
          prop="title"
          style="display: none"
        >
          <el-input
            type="text"
            :placeholder="$t('t-title-card-item')"
            v-model="DataCardItems.value.title"
            @change="
              DataCardItems.value.title = DataCardItems.value.title.trim()
            "
          />
        </el-form-item>

        <!-- collap -->
        <el-collapse
          model-value="0"
          class="config-card-item"
          v-if="
            DataCardItems.value.type?.includes('table') ||
            DataCardItems.value.type?.includes('chart') ||
            DataCardItems.value.type?.includes('network') ||
            DataCardItems.value.type?.includes('copy')
          "
        >
          <el-collapse-item :title="$t('t_advanced_config')" name="0">
            <div class="optionCardItem" style="display: none">
              <div class="optionCardItem__header">
                <i class="ri-price-tag-3-fill"></i>
                {{ $t("t-options-title") }}
              </div>
              <div class="optionCardItem__body">
                <div class="position-relative">
                  <b-row>
                    <b-col md="4">
                      <el-form-item :label="$t('t-hide-header')" class="d-flex">
                        <el-switch
                          v-model="DataCardItems.value.general.header.is_hidden"
                        />
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item :label="$t('t-color-title')" class="d-flex">
                        <el-color-picker
                          v-model="DataCardItems.value.general.header.color"
                        />
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item
                        :label="$t('t-background-color-title')"
                        class="d-flex"
                      >
                        <el-color-picker
                          v-model="
                            DataCardItems.value.general.header.backgroundColor
                          "
                        />
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div
                    class="tooltip-color"
                    @click="showDialogTooltipColors = !showDialogTooltipColors"
                  >
                    <i class="ri-question-line"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="optionCardItem">
              <div class="optionCardItem__header">
                <i class="ri-calendar-event-fill"></i>
                {{ $t("t-options-offset-date") }}
              </div>
              <div class="optionCardItem__body">
                <b-row>
                  <b-col md="6">
                    <el-form-item
                      :label="$t('t-prev-date-data')"
                      class="d-flex"
                    >
                      <el-input-number
                        type="number"
                        controls-position="right"
                        :placeholder="$t('t-place-prev-date-data')"
                        v-model="DataCardItems.value.settings.add_start_days"
                      />
                    </el-form-item>
                  </b-col>
                  <b-col md="6">
                    <el-form-item
                      :label="$t('t-next-date-data')"
                      class="d-flex"
                    >
                      <el-input-number
                        type="number"
                        controls-position="right"
                        :placeholder="$t('t-place-next-date-data')"
                        v-model="DataCardItems.value.settings.add_end_days"
                      />
                    </el-form-item>
                  </b-col>
                </b-row>
              </div>
            </div>

            <el-form-item v-if="DataCardItems.value.type.includes('chart')">
              <div class="optionCardItem">
                <div class="optionCardItem__header">
                  <i class="ri-pie-chart-2-fill"></i>
                  {{ $t("t-options-chart") }}
                </div>
                <div class="optionCardItem__body">
                  <div class="title-opitons">
                    <p>{{ $t("t-title-card-item") }}</p>
                    <el-form-item
                      :label="$t('t-content')"
                      class="title-options__title"
                    >
                      <el-input
                        type="text"
                        :placeholder="$t('t-title-card-item')"
                        v-model="DataCardItems.value.chart.title.text"
                      />
                    </el-form-item>
                    <div class="position-relative">
                      <b-row>
                        <b-col md="4">
                          <el-form-item
                            :label="$t('t-display-title')"
                            class="d-flex"
                          >
                            <el-switch
                              v-model="DataCardItems.value.chart.title.show"
                            />
                          </el-form-item>
                        </b-col>
                        <b-col md="4">
                          <el-form-item
                            :label="$t('t-table-config-font_size')"
                            class="d-flex"
                          >
                            <el-input-number
                              :min="1"
                              controls-position="right"
                              v-model="
                                DataCardItems.value.chart.title.textStyle
                                  .fontSize
                              "
                            />
                          </el-form-item>
                        </b-col>
                        <b-col md="4">
                          <el-form-item
                            :label="$t('t_text_color')"
                            class="d-flex"
                          >
                            <el-color-picker
                              v-model="
                                DataCardItems.value.chart.title.textStyle.color
                              "
                            />
                          </el-form-item>
                        </b-col>
                      </b-row>
                      <div
                        class="tooltip-color"
                        @click="
                          showDialogTooltipColors = !showDialogTooltipColors
                        "
                      >
                        <i class="ri-question-line"></i>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="DataCardItems.value.type.includes('chart-pie')"
                    class="legend-options"
                  >
                    <p>{{ $t("t-legend") }}</p>
                    <div class="legend-options__items">
                      <div class="legend-options__item">
                        <el-form-item
                          :label="$t('t-type-legend')"
                          class="label-option"
                        >
                          <el-select
                            v-model="DataCardItems.value.chart.legend.orient"
                            :placeholder="$t('t-place-legend')"
                          >
                            <el-option
                              v-for="item in DataForm.formatLegend"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="legend-options__item">
                        <el-form-item
                          :label="$t('t-position-legend-horizontal')"
                          class="label-option"
                        >
                          <el-select
                            v-model="
                              DataCardItems.value.chart.legend.align.horizontal
                            "
                            placeholder="Select"
                          >
                            <el-option
                              v-for="item in DataForm.horizontalLegend"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="legend-options__item">
                        <el-form-item
                          :label="$t('t-position-legend-vertical')"
                          class="label-option"
                        >
                          <el-select
                            v-model="
                              DataCardItems.value.chart.legend.align.vertical
                            "
                            placeholder="$t('t-select')"
                          >
                            <el-option
                              v-for="item in DataForm.verticalLegend"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="grid-options">
                    <p>{{ $t("t-grid") }}</p>
                    <div class="grid-options__items">
                      <div class="grid-options__item">
                        <el-form-item
                          :label="$t('t-height-chart')"
                          class="d-flex"
                          ><div class="d-flex">
                            <div class="grid-options__input">
                              <el-input-number
                                type="number"
                                controls-position="right"
                                :placeholder="$t('t-min-height-card-item')"
                                v-model="DataCardItems.value.chart.y_axis.min"
                              />
                            </div>
                            <div class="grid-options__input">
                              <el-input-number
                                type="number"
                                controls-position="right"
                                :placeholder="$t('t-max-height-card-item')"
                                v-model="DataCardItems.value.chart.y_axis.max"
                              />
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <div class="grid-options__item">
                        <el-form-item
                          :label="$t('t-rotate-label-chart')"
                          class="d-flex"
                        >
                          <el-input-number
                            type="number"
                            :min="-90"
                            :max="90"
                            controls-position="right"
                            :placeholder="$t('t-place-rotate-label-chart')"
                            v-model="
                              DataCardItems.value.chart.y_axis.axisLabel.rotate
                            "
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="position-relative">
                    <color-gradient-bar-chart
                      :colorConfigIn="colorConfigIn"
                      @colorConfigOut="colorConfigOut"
                    >
                    </color-gradient-bar-chart>
                    <div
                      class="tooltip-color"
                      @click="
                        showDialogTooltipColors = !showDialogTooltipColors
                      "
                    >
                      <i class="ri-question-line"></i>
                    </div>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-smooth-line')" class="d-flex">
                        <el-switch v-model="DataCardItems.value.chart.smooth" />
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item
                        :label="$t('t-border-radius-conner')"
                        class="label-option"
                        prop="chart.itemStyle.borderRadius"
                      >
                        <el-select
                          v-model="DataCardItems.value.chart.borderRadiusType"
                          :placeholder="$t('t-select-border-radius-type')"
                        >
                          <el-option
                            v-for="item in DataForm.typeBorder"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </el-form-item>
            <el-form-item v-if="DataCardItems.value.type.includes('table')">
              <div class="optionCardItem">
                <div class="optionCardItem__header">
                  <i class="ri-table-fill"></i>
                  {{ $t("t-options-table") }}
                </div>
                <div class="optionCardItem__body">
                  <el-checkbox
                    v-model="DataCardItems.value.table.hasNo"
                    :label="$t('t-show-No-table')"
                    size="large"
                  />
                  <el-checkbox
                    v-model="DataCardItems.value.table.isPaging"
                    :label="$t('t-pagination-table')"
                    size="large"
                  />
                  <p>{{ $t("t-info-format-columns") }}</p>

                  <div class="d-flex justify-content-center">
                    <div class="render-table">
                      <div
                        v-for="(item, index) in dataTableConfigRender"
                        :key="item.id"
                        class="d-flex"
                      >
                        <table-columns-config
                          :optionColumns="item"
                          :indexColumns="index"
                          :key="keyTableConfig"
                          @editColums="editColums"
                          @deleteColums="deleteColums"
                        ></table-columns-config>
                      </div>
                    </div>

                    <div
                      class="btn-add-config-table"
                      @click="showModalTableColumnsConfig"
                    >
                      <i class="ri-add-circle-line"></i>
                    </div>
                  </div>

                  <!-- dialog hiển thị cấu hình từng cột table -->
                  <el-dialog
                    v-model="showTableColumnsConfig"
                    :title="$t('t-bar-interface-config')"
                    width="70%"
                    :before-close="beforeCloseDialogTableConfig"
                    :close-on-click-modal="false"
                    align-center
                    custom-class="table-format-cell"
                  >
                    <b-row class="table-format">
                      <b-col md="7">
                        <el-form-item
                          :label="$t('t-table-config-code')"
                          class="d-flex"
                        >
                          <span
                            v-show="
                              formatTableColumnsValue.value.filter.is_allowed
                            "
                            class="label-requied"
                            slots="label"
                            >*</span
                          >
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.code"
                            :placeholder="$t('t-place-table-config-code')"
                            @change="setValueCode()"
                            clearable
                          />
                        </el-form-item>

                        <el-form-item
                          :label="$t('t-table-config-field_name')"
                          class="d-flex"
                        >
                          <span class="label-requied" slots="label">*</span>
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.field_name"
                            :placeholder="
                              $t('t-place-table-config-field_name-column')
                            "
                            clearable
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-table-config-title_name')"
                          class="d-flex"
                        >
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.title_name"
                            :placeholder="
                              $t('t-place-table-config-title_name-column')
                            "
                            clearable
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-table-config-width')"
                          class="d-flex"
                        >
                          <el-input-number
                            v-model="formatTableColumnsValue.value.width"
                            :placeholder="$t('t-place-table-config-width')"
                            :min="1"
                            controls-position="right"
                          >
                          </el-input-number>
                          <span class="px-style">px</span>
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-table-config-font_size-column')"
                          class="d-flex"
                        >
                          <el-input-number
                            v-model="formatTableColumnsValue.value.font_size"
                            :placeholder="$t('t-place-table-config-font_size')"
                            :min="1"
                            controls-position="right"
                          >
                          </el-input-number>
                          <span class="px-style">px</span>
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-table-config-align')"
                          class="d-flex"
                        >
                          <el-select
                            v-model="formatTableColumnsValue.value.align"
                            :placeholder="$t('t-place-table-config-align')"
                          >
                            <el-option
                              v-for="item in DataForm.formatAlign"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-table-config-type')"
                          class="d-flex"
                        >
                          <el-select
                            v-model="formatTableColumnsValue.value.type"
                            :placeholder="$t('t-place-table-config-type')"
                          >
                            <el-option
                              v-for="item in DataForm.typeColumns"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-table-config-visible')"
                          class="d-flex"
                        >
                          <el-select
                            v-model="formatTableColumnsValue.value.visible"
                            :placeholder="$t('t-place-table-config-visible')"
                          >
                            <el-option
                              v-for="item in DataForm.visibleColumns"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-hide-bar-default')"
                          class="d-flex"
                        >
                          <el-switch
                            v-model="formatTableColumnsValue.value.defaultHide"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-display-bar-icon')"
                          class="d-flex"
                        >
                          <el-switch
                            class="switch-icon-columns"
                            v-model="formatTableColumnsValue.value.icon.visible"
                          />
                          <div class="icon-columns">
                            <img
                              v-if="
                                formatTableColumnsValue.value.icon.file_id
                                  ?.length > 0
                              "
                              :src="
                                renderIconColumns(
                                  formatTableColumnsValue.value.icon.file_id
                                )
                              "
                              alt=""
                            />
                            <img
                              v-else
                              src="@/assets/icon/none-icon.svg"
                              alt=""
                            />
                          </div>
                          <div>
                            <select-icons-columns
                              :listIcons="listIconsColumns"
                              :disabled="
                                !formatTableColumnsValue.value.icon.visible
                              "
                              @setIcon="setIcon"
                              :key="keySelectIcon"
                            ></select-icons-columns>
                          </div>
                          <!-- nút tải lên -->
                          <span style="margin: 0 5px">{{ $t("t-or") }}</span>
                          <div class="" @click="keySelectIcon += 1">
                            <label
                              for="uploadLogo"
                              class="label-upload-icons"
                              :class="{
                                disableUploadIcon:
                                  !formatTableColumnsValue.value.icon.visible,
                              }"
                            >
                              <img
                                src="../../assets/icon/upload_white.svg"
                                alt="upload"
                              />
                              <span>{{ $t("t_upload") }}</span>
                            </label>
                          </div>

                          <input
                            type="file"
                            accept=".jpg, .png, .jpeg, .svg, .JPEG, .ico"
                            :disabled="
                              !formatTableColumnsValue.value.icon.visible
                            "
                            id="uploadLogo"
                            style="display: none"
                            @change="uploadIconColumns"
                          />
                        </el-form-item>
                      </b-col>
                      <b-col md="5">
                        <el-form-item
                          :label="$t('t-color-title')"
                          class="d-flex"
                        >
                          <el-color-picker
                            v-model="formatTableColumnsValue.value.color"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('font_style_bold')"
                          class="d-flex"
                        >
                          <el-switch
                            v-model="formatTableColumnsValue.value.bold"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('font_style_italic')"
                          class="d-flex"
                        >
                          <el-switch
                            v-model="formatTableColumnsValue.value.italic"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('font_style_underline')"
                          class="d-flex"
                        >
                          <el-switch
                            v-model="formatTableColumnsValue.value.underline"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t_default_sort')"
                          class="sort-default"
                        >
                          <el-input-number
                            style="width: 140px"
                            :precision="0"
                            v-model="formatTableColumnsValue.value.sort.default"
                            controls-position="right"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-allow-sort')"
                          class="d-flex"
                        >
                          <el-switch
                            v-model="
                              formatTableColumnsValue.value.sort.is_allowed
                            "
                            @change="changeAllowSortAndFilter('sort')"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-type-sort')"
                          class="d-flex"
                          :class="{
                            disabledLabel:
                              !formatTableColumnsValue.value.sort.is_allowed,
                          }"
                        >
                          <el-select
                            v-model="formatTableColumnsValue.value.sort.type"
                            :placeholder="$t('t-type-sort')"
                            :disabled="
                              !formatTableColumnsValue.value.sort.is_allowed
                            "
                            style="width: 140px"
                          >
                            <el-option
                              v-for="item in DataForm.typeSort"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>

                        <el-form-item
                          :label="$t('t-allow-filter')"
                          class="d-flex"
                        >
                          <el-switch
                            v-model="
                              formatTableColumnsValue.value.filter.is_allowed
                            "
                            @change="changeAllowSortAndFilter('filter')"
                          />
                        </el-form-item>
                        <el-form-item
                          :label="$t('t-type-filter')"
                          class="d-flex"
                          :class="{
                            disabledLabel:
                              !formatTableColumnsValue.value.filter.is_allowed,
                          }"
                        >
                          <el-select
                            v-model="
                              formatTableColumnsValue.value.filter.input_type
                            "
                            :placeholder="$t('t-type-filter')"
                            :disabled="
                              !formatTableColumnsValue.value.filter.is_allowed
                            "
                            style="width: 140px"
                          >
                            <el-option
                              v-for="item in DataForm.typeFilter"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          :label="$t('t_header_tooltip')"
                          class="d-flex"
                        >
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.tooltip"
                            :placeholder="$t('t_place_tooltip')"
                          />
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <el-form-item :label="$t('t-table-formatString')">
                      <el-input
                        type="textarea"
                        :rows="5"
                        v-model="formatTableColumnsValue.value.formatString"
                        :placeholder="$t('t-place-table-formatString')"
                        clearable
                        :input-style="{
                          wordBreak: 'break-word',
                        }"
                      />
                    </el-form-item>
                    <el-form-item>
                      <el-checkbox
                        v-model="showFormatLink"
                        :label="$t('t-configure-conditions-for-Links')"
                        size="large"
                      />
                    </el-form-item>
                    <el-form-item v-show="showFormatLink">
                      <template #label>
                        <span
                          >{{
                            $t("t-field-name-contains-the-comparison-value")
                          }}
                        </span>
                        <span class="label-requied" slots="label">*</span>
                      </template>
                      <el-input
                        type="text"
                        v-model="
                          formatTableColumnsValue.value.cellLink.valueColumn
                        "
                        :placeholder="$t('t-name-of-the-column-after-As')"
                        @change="setNameCellLink()"
                        clearable
                      />
                    </el-form-item>
                    <el-form-item v-show="showFormatLink" class="table-link">
                      <el-table :data="dataLink.value">
                        <el-table-column min-width="50" align="start">
                          <template #header>
                            <span>{{ $t("t-list-comparison-values") }}</span>
                            <span class="label-requied" slots="label">*</span>
                          </template>
                          <template #default="scope">
                            <el-tag
                              v-for="tag in scope.row.listValue"
                              :key="tag"
                              class="mx-1"
                              closable
                              :disable-transitions="false"
                              @close="handleClose(tag, scope.$index)"
                            >
                              {{ tag }}
                            </el-tag>
                            <el-input
                              ref="InputRef"
                              v-model="scope.row.inputValue"
                              class="ml-1 w-20"
                              style="width: 120px"
                              :placeholder="$t('t-comparison-value')"
                              :class="{
                                error_value:
                                  errorValue[scope.$index] === scope.$index,
                              }"
                              @keyup.enter="
                                handleInputConfirm(
                                  scope.$index,
                                  scope.row.inputValue
                                )
                              "
                              @blur="
                                handleInputConfirm(
                                  scope.$index,
                                  scope.row.inputValue
                                )
                              "
                            />
                            <span
                              v-if="errorValue[scope.$index] === scope.$index"
                              class="error"
                              >{{ $t("valid_required") }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column min-width="100" align="start">
                          <template #header>
                            <span>{{ $t("format_string") }}</span>
                            <span class="label-requied" slots="label">*</span>
                          </template>
                          <template #default="scope">
                            <el-input
                              type="textarea"
                              :autosize="{ minRows: 1, maxRows: 4 }"
                              v-model="scope.row.formatString"
                              :placeholder="$t('t_place_format_string')"
                              :class="{
                                error_value:
                                  errorFormatString[scope.$index] ===
                                  scope.$index,
                              }"
                              @change="
                                changeLink(
                                  scope.row.listValue,
                                  scope.row.formatString,
                                  scope.$index,
                                  'formatString'
                                )
                              "
                            />
                            <span
                              v-if="
                                errorFormatString[scope.$index] === scope.$index
                              "
                              class="error"
                              >{{ $t("valid_required") }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="$t('t-action')"
                          align="center"
                          width="100px"
                        >
                          <template #default="scope">
                            <div class="btn-group-thao-tac">
                              <button
                                type="button"
                                class="btn btn-soft-info waves-effect waves-light btn-sm"
                                v-b-popover.hover.left="$t('t-add')"
                                v-if="scope.row.action === 'add'"
                                @click="addParam(scope.row, scope.$index)"
                              >
                                <i class="ri-add-circle-line"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-soft-danger waves-effect waves-light btn-sm"
                                v-b-popover.hover.left="$t('t-delete')"
                                v-else
                                @click="deleteFormmatLink(scope.$index)"
                              >
                                <i class="ri-delete-bin-6-line"></i>
                              </button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                    <el-form-item
                      v-show="showFormatLink"
                      :label="
                        $t('format_string_for_other_comparison_values_above')
                      "
                    >
                      <el-input
                        type="textarea"
                        :rows="5"
                        v-model="
                          formatTableColumnsValue.value.cellLink.defaultLink
                            .formatString
                        "
                        :placeholder="`${$t('t_place_format_string')} (${$t(
                          't-not-required'
                        )})`"
                        clearable
                        :input-style="{
                          wordBreak: 'break-word',
                        }"
                      />
                    </el-form-item>
                    <div class="text-center">
                      <el-button
                        type="primary"
                        @click="createTableColumnsConfig"
                        :loading="loadingBtn"
                        v-if="stypeTableConfig == 'create'"
                        :disabled="
                          !formatTableColumnsValue.value.field_name ||
                          (formatTableColumnsValue.value.filter?.is_allowed &&
                            !formatTableColumnsValue.value?.code) ||
                          (showFormatLink &&
                            (!formatTableColumnsValue.value.cellLink
                              .valueColumn ||
                              !formatTableColumnsValue.value.cellLink.valueColumn.trim() ||
                              dataLink.value.length <= 1))
                        "
                        >{{ $t("t-confirm") }}</el-button
                      >
                      <el-button
                        type="primary"
                        @click="updateTableColumnsConfig"
                        :loading="loadingBtn"
                        v-if="stypeTableConfig == 'update'"
                        :disabled="
                          !formatTableColumnsValue.value.field_name ||
                          (formatTableColumnsValue.value.filter?.is_allowed &&
                            !formatTableColumnsValue.value?.code) ||
                          (showFormatLink &&
                            (!formatTableColumnsValue.value.cellLink
                              .valueColumn ||
                              !formatTableColumnsValue.value.cellLink.valueColumn.trim() ||
                              dataLink.value.length <= 1))
                        "
                        >{{ $t("t-confirm") }}</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </div>
            </el-form-item>

            <el-form-item
              :label="$t('t-network')"
              v-if="DataCardItems.value.type.includes('network')"
            >
              <el-select
                v-model="DataCardItems.value.network.service_ids"
                multiple
                :multiple-limit="
                  DataCardItems.value.type === 'stats-network-multi-serv'
                    ? 20
                    : 1
                "
                filterable
                default-first-option
                :reserve-keyword="false"
                :placeholder="$t('t-network-service')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listService"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-secondary btn-border btn-select-type mx-2"
            @click="stepNumber -= 1"
            v-if="props.typeShowCardItemConfig === 'create'"
          >
            {{ $t("t-back") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            @click="stepNumber += 1"
            :disabled="!DataCardItems.value.title"
          >
            {{ $t("t-next") }}
          </button>
        </div>
      </div>

      <div v-if="stepNumber == 3">
        <el-form-item :label="$t('t-service')" prop="service_id">
          <el-select
            filterable
            v-model="DataCardItems.value.service_id"
            :placeholder="$t('t-select-service')"
            @change="getIdService"
            class="service-card-items"
            :disabled="DataCardItems.value.disableService"
          >
            <el-option
              v-for="item in listService"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('t-table-config')" prop="table_config_id">
          <el-select
            filterable
            v-model="DataCardItems.value.table_config_id"
            :placeholder="$t('t-select-table-config')"
          >
            <el-option
              v-for="item in listTableConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="SQL" prop="sql">
          <el-input
            v-model="DataCardItems.value.sql"
            :placeholder="$t('t-place-sql')"
            :rows="5"
            type="textarea"
            :input-style="{
              wordBreak: 'break-word',
            }"
            @change="validateSql(ruleFormRef)"
          />
        </el-form-item>
        <el-form-item
          :label="$t('t-sql-table-formats')"
          prop="sql_table_formats"
        >
          <el-input
            v-model="DataCardItems.value.sql_table_formats"
            :placeholder="$t('t-place-sql-formats')"
            :rows="5"
            type="textarea"
            :input-style="{
              wordBreak: 'break-word',
            }"
          />
        </el-form-item>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-secondary btn-border btn-select-type mx-2"
            @click="stepNumber -= 1"
          >
            {{ $t("t-back") }}
          </button>
          <el-button
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border mx-2"
            @click="addCardItems(ruleFormRef)"
            v-if="props.typeShowCardItemConfig === 'create'"
          >
            {{
              DataCardItems.value.type?.includes("table")
                ? $t("t-add-table")
                : $t("t-add-chart")
            }}
          </el-button>
          <el-button
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border mx-2"
            @click="updateCardItems(ruleFormRef)"
            v-if="props.typeShowCardItemConfig === 'update'"
          >
            {{
              DataCardItems.value.type?.includes("table")
                ? $t("t-update-table")
                : $t("t-update-chart")
            }}
          </el-button>
        </div>
      </div>
    </el-form>
  </el-dialog>
  <el-dialog
    v-model="showDialogTooltipColors"
    :title="$t('t_suggested_color_palette')"
    width="60%"
    :close-on-click-modal="false"
  >
    <tooltip-colors></tooltip-colors>
  </el-dialog>
</template>

<script setup>
import mushroom from "cem-primary-api";
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from "vue";
import Swal from "sweetalert2";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Validate from "@/service/ValidService";
import MethodService from "@/service/MethodService";
import DataForm from "./dataFormCardItemsConfig";
import TooltipColors from "../TooltipColors.vue";
import RenderIconChart from "@/components/RenderIconChart.vue";
import TableColumnsConfig from "@/components/TableColumnsConfig.vue";
import ColorGradientBarChart from "@/components/ColorGradientBarChart.vue";
import SelectIconsColumns from "@/components/SelectIconsColumns.vue";
import { ListService } from "@/stores/ListService";
import table from "@/assets/images/table.svg";
import { Filter } from "mushroomjs";
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
//car-item infoSingle nay khach chua yeu cau
// import infoSingle from "../../assets/images/chart/info-single.svg";

const router = useRoute();
const centerDialogVisible = ref(false);
const showTableColumnsConfig = ref(false);
const loadingBtn = ref(false);
const titleCardItem = ref("");
const infoChartType = ref("");
const listService = ref([]);
const listTableConfig = ref([]);
const keySelectIcon = ref(0);
const idService = ref("");
const indexTableColumnsConfig = ref(""); // vị trí cột của bảng table config
const tempDataTableColumsConfig = ref([]); // lưu tạm giá trị của table config
const stypeTableConfig = ref("create"); // trạng thái của table config
const showDialogTooltipColors = ref(false);
const { t } = useI18n();
const lang_web = langWeb();
const ruleFormRef = ref();
const DataCardItems = reactive({
  value: MethodService.copyObject(DataForm.dataCardItems),
});
const formatTableColumnsValue = reactive({
  value: MethodService.copyObject(DataForm.formatColumnsTable),
});
const storeListService = ListService();
const emit = defineEmits([""]);
const props = defineProps({
  cardIdToCardItemsConfig: String,
  cardTitleToCardItemsConfig: String,
  dataTableConfig: Array,
  idCardItem: String,
  showCardItemConfig: Boolean,
  stepNumberForCardItem: Number,
  typeShowCardItemConfig: String,
  typeTableScreen: String,
});
const showSelectIconForColumns = ref(false);
const stepNumber = ref(1);
const activeNames = ref(["0"]);
const checkFalse = ref(0);
const dataTableConfigRender = ref([]);
const dataChartConfigIn = ref([]);
const colorConfigIn = ref([]);
const listIconsColumns = ref([]);
const nameDashboard = ref("");
const nameDashboardService = ref("");
const nameCard = ref("");
const listCardItems = ref([]);
const idCardSelect = ref("");
const cardItemsBlock = ref([]);
const sumHeightCardItem = ref(0);
const loadingGetListCardItems = ref(false);
const cardItemCopyObj = ref({});
const typeTable = ref("");
const showFormatLink = ref(false);
const dataLink = reactive({
  value: [
    {
      listValue: [],
      formatString: undefined,
      inputValue: undefined,
      action: "add",
    },
  ],
});
const errorFormatString = ref([]);
const errorValue = ref([]);
const inputVisible = ref(false);
const InputRef = ref();

const setValueCode = () => {
  formatTableColumnsValue.value.code = formatTableColumnsValue.value.code
    ? formatTableColumnsValue.value.code.trim()
    : undefined;
};
const handleClose = (tag, index) => {
  dataLink.value[index].listValue.splice(
    dataLink.value[index].listValue.indexOf(tag),
    1
  );
  errorValue.value[index] =
    dataLink.value[index].listValue.length === 0 ? index : undefined;
};
const handleInputConfirm = (index, inputValue) => {
  if (inputValue && inputValue.trim()) {
    dataLink.value[index].listValue.push(inputValue.trim());
    errorValue.value[index] =
      dataLink.value[index].listValue.length === 0 ? index : undefined;
  }
  inputVisible.value = false;
  dataLink.value[index].inputValue = "";
};
const changeLink = (tag, value, index, label) => {
  if (label === "listValue") {
    errorValue.value[index] = tag.length === 0 ? index : undefined;
  }
  if (label === "formatString") {
    errorFormatString.value[index] = value && value.trim() ? undefined : index;
    dataLink.value[index].formatString = value ? value.trim() : undefined;
  }
};
const addParam = (row, index) => {
  if (row.listValue.length > 0 && row.formatString) {
    const length = dataLink.value.length;
    dataLink.value[length - 1] = {
      listValue: row.listValue,
      formatString: row.formatString,
      inputValue: row.inputValue,
      action: "",
    };
    dataLink.value.push({
      listValue: [],
      formatString: undefined,
      inputValue: undefined,
      action: "add",
    });
  } else {
    errorValue.value[index] = row.listValue.length === 0 ? index : undefined;
    errorFormatString.value[index] = row.formatString ? undefined : index;
  }
};
const deleteFormmatLink = (index) => {
  Swal.fire({
    title: t("swal_noti"),
    text: t("t_delete_string_comparison"),
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    cancelButtonText: t("swal_cancel"),
    confirmButtonText: t("swal_accept"),
    showCancelButton: true,
    allowOutsideClick: false,
    customClass: {
      container: "swalDeleteColumnConfig",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      dataLink.value.splice(index, 1);
      errorValue.value[index] = undefined;
      errorFormatString.value[index] = undefined;
    }
  });
};
const linkImg = (type) => {
  if (type == "table") return table;
  // if (type == "info-single") return infoSingle;
};
const validateSql = (formEl) => {
  if (!formEl) return;
  formEl.validateField("sql");
};
const resetFormatTableColumnsValue = () => {
  formatTableColumnsValue.value = MethodService.copyObject(
    DataForm.formatColumnsTable
  );
  showFormatLink.value = false;
  dataLink.value = [
    {
      listValue: [],
      formatString: undefined,
      inputValue: undefined,
      action: "add",
    },
  ];
};
const countClickCardItemBlock = ref(0);
const clickCardItemBlock = async (item) => {
  countClickCardItemBlock.value += 1;
  Array.from(document.querySelectorAll(".card-item__block")).forEach(function (
    el
  ) {
    el.classList.remove("active");
  });
  document.getElementById(item.id).classList.add("active");
  cardItemCopyObj.value = { ...item };
};
const addCardItemCopy = async () => {
  cardItemCopyObj.value.title += "-Copy";
  cardItemCopyObj.value.y = sumHeightCardItem.value;
  cardItemCopyObj.value.card_id = props.cardIdToCardItemsConfig;
  try {
    const newId = await mushroom.card_item.createAsync(cardItemCopyObj.value);
    cardItemCopyObj.value.id = newId;
  } catch (error) {
    toastr.error(t("t_copy_card_item_error"));
  }
};
const copyCardItemNext = async () => {
  await addCardItemCopy();
  stepNumber.value += 1;
  DataCardItems.value = cardItemCopyObj.value;
  DataCardItems.value.id = cardItemCopyObj.value.id.result;
  dataTableConfigRender.value = cardItemCopyObj.value.table?.format;

  await getIdService(router.params.id);
  DataCardItems.value.service_id = router.params.id;
  DataCardItems.value.disableService = true;
  emit("changeTypeShowCardItemConfig", "update");
};
const disableSelectListService = ref(true);
const getListDashboard = async (type) => {
  countClickCardItemBlock.value = 0;
  nameCard.value = "";
  type == "service"
    ? (disableSelectListService.value = false)
    : (disableSelectListService.value = true);
  if (router.name == "DichVu" && type == "service") {
    disableSelectListService.value = true;
    nameDashboardService.value = router.params.id;
    await getListDashboardService(router.params.id);
  } else {
    nameDashboardService.value = "";
    try {
      const response = await mushroom.card.getAllAsync({
        filters: Filter.eq("dashboard", type).build(),
        sort: "index",
      });
      listCardItems.value = [];
      cardItemsBlock.value = [];
      if (response.result?.length > 0) {
        if (type !== "service") {
          response.result.forEach((item) => {
            listCardItems.value.push({
              value: item.id,
              label: item.title,
            });
          });
        }
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
      MethodService.showError(e.code);
    }
  }
};
const getListDashboardService = async (idService) => {
  listCardItems.value = [];
  cardItemsBlock.value = [];
  countClickCardItemBlock.value = 0;
  nameCard.value = "";
  try {
    const response = await mushroom.card.getAllAsync({
      fields: "id,dashboard,service_id,uc_id,title,header,index",
      filters: Filter.and(
        Filter.eq("dashboard", "service"),
        Filter.eq("service_id", idService)
      ).build(),
    });

    if (response.result?.length > 0) {
      response.result.forEach((item) => {
        listCardItems.value.push({
          value: item.id,
          label: item.title,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getListCardItems = async (e) => {
  idCardSelect.value = e;
  countClickCardItemBlock.value = 0;
  cardItemsBlock.value = [];
  loadingGetListCardItems.value = true;
  try {
    const response = await mushroom.card_item.getAllAsync({
      filters: Filter.eq("card_id", idCardSelect.value).build(),
    });

    if (response.result?.length > 0) {
      cardItemsBlock.value = response.result;
      response.result.forEach((item) => {
        sumHeightCardItem.value += item.height;
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  } finally {
    loadingGetListCardItems.value = false;
  }
};

const getListIconsColumns = async () => {
  try {
    const response = await mushroom.icon.getAllAsync();
    if (response.result?.length > 0)
      listIconsColumns.value = response.result.map((item) => item.file_id);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const renderIconColumns = (iconId) => {
  if (!iconId) return;
  return mushroom.$file.linkBuilder.thumb.id(iconId).build();
};
const uploadIconColumns = async () => {
  try {
    let nameImage = document.getElementById("uploadLogo").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg" &&
        typeImage !== ".svg" &&
        typeImage !== ".ico"
      ) {
        toastr.warning(t("t_select_format_image"));
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("uploadLogo").files[0],
        });
        if (res.result) {
          formatTableColumnsValue.value.icon.file_id = res.result;
          updateIconId(res.result);
          getIconForColumn(res.result);
          keySelectIcon.value += 1;
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const setIcon = async (fileId) => {
  formatTableColumnsValue.value.icon.file_id = fileId;
  await getIconForColumn(fileId);
};
const updateIconId = async (id) => {
  const icon_object = {
    file_id: id,
  };
  try {
    await mushroom.icon.createAsync(icon_object);
    await getListIconsColumns();
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getIconForColumn = async (id) => {
  formatTableColumnsValue.value.icon.urlIcon =
    await mushroom.$file.linkBuilder.thumb.id(id).build();
};
const changeAllowSortAndFilter = (type) => {
  if (type == "sort") formatTableColumnsValue.value.sort.type = undefined;
  if (type == "filter")
    formatTableColumnsValue.value.filter.input_type = undefined;
};
const validateSqlFormats = (rule, value, callback) => {
  if (value.length > 0) {
    let stringSQL = value.split("\n");
    checkFalse.value = 0;
    stringSQL.forEach((item) => {
      if (!item.includes("@table_name")) checkFalse.value += 1;
    });
    checkFalse.value > 0 ? callback(t("t-place-sql-formats")) : callback();
  } else callback();
};
const rulesForm = reactive({
  title: [
    Validate.checkMaxLength(50),
    Validate.checkSpaceString,
    Validate.required,
  ],
  type: [Validate.required, Validate.requiredChange],
  table_config_id: [Validate.required, Validate.requiredChange],
  service_id: [Validate.required, Validate.requiredChange],
  sql: [Validate.required, Validate.sql],
  sql_table_formats: [{ validator: validateSqlFormats, trigger: "blur" }],
  field_name: [Validate.required],
  formatTableColumnsValue: {
    code: [Validate.required],
  },
});

const deleteColums = (index) => {
  Swal.fire({
    title: t("swal_noti"),
    text: t("swal_columns_delete"),
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    cancelButtonText: t("swal_cancel"),
    confirmButtonText: t("swal_accept"),
    showCancelButton: true,
    allowOutsideClick: false,
    customClass: {
      container: "swalDeleteColumnConfig",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      dataTableConfigRender.value.splice(index, 1);
    }
  });
};
const editColums = async (index, data) => {
  // hiển thị object dữ liệu cột ở vị trí bấm
  if (
    data.cellLink &&
    data.cellLink?.valueColumn &&
    Object.keys(data.cellLink).length > 0
  ) {
    showFormatLink.value = true;
    if (data.cellLink?.links.length > 0) {
      let listLinkCell = [];

      data.cellLink.links
        .filter((item) => item.formatString)
        .forEach((item) => {
          listLinkCell.push({
            listValue: item.values,
            formatString: item.formatString,
            inputValue: undefined,
            action: "",
          });
        });
      listLinkCell.push({
        listValue: [],
        formatString: undefined,
        inputValue: undefined,
        action: "add",
      });
      dataLink.value = listLinkCell;
    }
  } else {
    showFormatLink.value = false;
    dataLink.value = [
      {
        listValue: [],
        formatString: undefined,
        inputValue: undefined,
        action: "add",
      },
    ];
  }
  await getListIconsColumns();
  errorValue.value = errorValue.value.map(() => undefined);
  errorFormatString.value = errorFormatString.value.map(() => undefined);
  indexTableColumnsConfig.value = index;
  stypeTableConfig.value = "update";
  showTableColumnsConfig.value = true;
  formatTableColumnsValue.value = { ...data };
  keySelectIcon.value += 1;
};

const setNameCellLink = () => {
  formatTableColumnsValue.value.cellLink.valueColumn = formatTableColumnsValue
    .value.cellLink.valueColumn
    ? formatTableColumnsValue.value.cellLink.valueColumn.trim()
    : undefined;
};
const updateTableColumnsConfig = () => {
  // cập nhật 1 obj table config theo vị trí hiện tại
  formatTableColumnsValue.value.showCellLink = showFormatLink.value;
  formatTableColumnsValue.value.cellLink.defaultLink.formatString =
    formatTableColumnsValue.value.cellLink.defaultLink.formatString
      ? formatTableColumnsValue.value.cellLink.defaultLink.formatString.trim()
      : undefined;

  const checkLink = dataLink.value.filter(
    (item) =>
      (!item.formatString || item.listValue.length == 0) && item.action === ""
  ).length;
  const dataFormTableColumn = { ...formatTableColumnsValue.value };
  if (checkLink > 0 && showFormatLink) return;
  showTableColumnsConfig.value = false;
  const listLinkCell = dataLink.value
    .filter((item) => item.action === "")
    .map((item) => {
      return {
        values: item.listValue,
        formatString: item.formatString,
      };
    });
  dataFormTableColumn.showCellLink =
    dataFormTableColumn.cellLink.valueColumn && showFormatLink.value
      ? true
      : false;
  dataFormTableColumn.cellLink.valueColumn =
    dataFormTableColumn.cellLink.valueColumn ?? null;
  dataFormTableColumn.cellLink.links = listLinkCell;
  dataTableConfigRender[indexTableColumnsConfig.value] = {
    ...dataFormTableColumn,
  };
  showTableColumnsConfig.value = false;
  DataCardItems.value.table.format[indexTableColumnsConfig.value] = {
    ...dataFormTableColumn,
  };
};
const createTableColumnsConfig = () => {
  // thêm mới 1 obj table config
  const checkLink = dataLink.value.filter(
    (item) =>
      (!item.formatString || item.listValue.length == 0) && item.action === ""
  ).length;
  formatTableColumnsValue.value.cellLink.defaultLink.formatString =
    formatTableColumnsValue.value.cellLink.defaultLink.formatString
      ? formatTableColumnsValue.value.cellLink.defaultLink.formatString.trim()
      : undefined;
  const dataFormTableColumn = { ...formatTableColumnsValue.value };

  if (checkLink > 0 && showFormatLink) return;
  showTableColumnsConfig.value = false;
  const listLinkCell = dataLink.value
    .filter((item) => item.action === "")
    .map((item) => {
      return {
        values: item.listValue,
        formatString: item.formatString,
      };
    });
  dataFormTableColumn.cellLink.links = listLinkCell;
  dataFormTableColumn.showCellLink = dataFormTableColumn.cellLink?.valueColumn
    ? true
    : false;
  dataFormTableColumn.cellLink.valueColumn = dataFormTableColumn.cellLink
    ?.valueColumn
    ? dataFormTableColumn.cellLink.valueColumn.trim()
    : null;
  dataTableConfigRender.value.push({ ...dataFormTableColumn });
};
const keyTableConfig = ref(0);
const showModalTableColumnsConfig = () => {
  stypeTableConfig.value = "create";
  showTableColumnsConfig.value = true;
  keyTableConfig.value += 1;
  keySelectIcon.value += 1;
  resetFormatTableColumnsValue();
};
const getAllTableConfig = async (idService) => {
  if (!props.typeTableScreen) return;
  try {
    const response = await mushroom.table_config.getAllAsync({
      filters: Filter.and(
        Filter.eq("service_id", idService),
        Filter.eq("type", props.typeTableScreen)
      ).build(),
    });

    listTableConfig.value = [];
    response.result.forEach((item) => {
      listTableConfig.value.push({
        value: item.id,
        label: item.name,
      });
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getAllService = async () => {
  listService.value = [];
  storeListService.data.forEach((item) => {
    listService.value.push({
      value: item.id,
      label: item.name,
    });
  });
  DataCardItems.value.disableService = false;
  if (router.name == "DichVu") {
    await getAllTableConfig(router.params.id);
    DataCardItems.value.service_id = router.params.id;
    DataCardItems.value.disableService = true;
  }
  if (router.name == "SessionDichVu") {
    await getAllTableConfig(router.query.serviceID);
    DataCardItems.value.service_id = router.query.serviceID;
    DataCardItems.value.disableService = true;
  }
};

const getIdService = async (event) => {
  idService.value = event;
  await getAllTableConfig(idService.value);
  DataCardItems.value.table_config_id = "";
};

const resetDataForm = () => {
  if (router.name !== "DichVu") {
    listTableConfig.value = [];
  }
  DataCardItems.value.title = undefined;
  ruleFormRef.value.resetFields();
};

const addCardItems = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (!valid) return;
    dataTableConfigRender.value.length > 0
      ? (DataCardItems.value.table.format = dataTableConfigRender.value)
      : (DataCardItems.value.table.format = []);
    emit("addNewCardItems", DataCardItems.value);
    stepNumber.value = 1;
    activeNames.value = 0;
    resetDataForm();
  });
};
const updateCardItems = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (!valid) return;
    emit("updateNewCardItems", DataCardItems.value);
    stepNumber.value = 2;
  });
};
const colorConfigOut = (data) => {
  DataCardItems.value.chart.data_serials = data.value || [];
};
const beforeCloseDialog = () => {
  dataTableConfigRender.value = [];
  dataChartConfigIn.value = [];
  colorConfigIn.value = [];
  listCardItems.value = [];
  cardItemsBlock.value = [];
  countClickCardItemBlock.value = 0;
  nameDashboard.value = "";
  nameDashboardService.value = "";
  nameCard.value = "";
  disableSelectListService.value = true;
  emit("closeDialogCardItemsConfig");
  if (props.typeShowCardItemConfig === "create") {
    resetDataForm();
    stepNumber.value = 1;
    DataCardItems.value.type = ""; //reset chọn loại card-item
  } else stepNumber.value = 2;
  infoChartType.value = "";
  activeNames.value = 0;
};
const beforeCloseDialogTableConfig = () => {
  keyTableConfig.value += 1;
  showTableColumnsConfig.value = false;
  showSelectIconForColumns.value = false;
  resetFormatTableColumnsValue();
};

watch(
  () => props.showCardItemConfig,
  (newCardItemConfig) => {
    newCardItemConfig == true
      ? (centerDialogVisible.value = true)
      : (centerDialogVisible.value = false);
    countClickCardItemBlock.value = 0;
  }
);
watch(
  () => props.stepNumberForCardItem,
  (newstepNumberForCardItem) => {
    stepNumber.value = Number(newstepNumberForCardItem);
    if (stepNumber.value === 2 && props.idCardItem)
      getInfoCardItems(props.idCardItem);
  }
);
watch(
  () => props.typeShowCardItemConfig,
  (typeShowCardItemConfig) => {
    typeShowCardItemConfig === "create"
      ? (stepNumber.value = 1)
      : (stepNumber.value = 2);
  }
);
watch(
  () => props.idCardItem,
  (newDataCardItem) => getInfoCardItems(newDataCardItem)
);

const getInfoCardItems = async (cardId) => {
  try {
    const response = await mushroom.card_item.findByIdAsync(
      {
        id: cardId,
      },
      {
        enabledCache: false,
      }
    );
    const tableConfigResponse = await mushroom.table_config.findByIdAsync({
      id: response.result.table_config_id,
    });

    response.result.sql_table_formats =
      response.result.sql_table_formats?.join("\n") ?? [];
    response.result.settings = response.result?.settings ?? {};
    if (!response.result.general) {
      response.result.general = {};
      response.result.general.header = {};
      response.result.general.header.color = "";
      response.result.general.header.backgroundColor = "";
    }

    response.result.table = response.result.table ?? {};
    response.result.table.format = response.result.table.format ?? [];

    response.result.table.format.forEach((item) => {
      if (!item.filter) {
        item.filter = {};
        item.filter.is_allowed = undefined;
        item.filter.input_type = undefined;
        item.filter.is_suggestion = undefined;
        item.filter.suggest_values = [];
      }
      if (!item.sort) {
        item.sort = {};
        item.sort.is_allowed = undefined;
        item.sort.type = undefined;
      }
      if (!item.icon) {
        item.icon = {};
        item.icon.visible = undefined;
        item.icon.file_id = undefined;
      }

      item.showCellLink = true;
      if (!item.cellLink) {
        item.showCellLink = false;
        item.cellLink = {};
        item.cellLink.valueColumn = undefined;
        item.cellLink.links = [];
        item.cellLink.defaultLink = {};
        item.cellLink.defaultLink.formatString = undefined;
      }
      if (!item.cellLink.defaultLink?.formatString) {
        item.cellLink.defaultLink = {};
        item.cellLink.defaultLink.formatString = undefined;
      }
    });

    DataCardItems.value = response.result;
    dataTableConfigRender.value = response.result.table.format;

    titleCardItem.value = response.result.title;
    DataCardItems.value.service_id =
      tableConfigResponse.result?.service_id ?? "";
    if (router.name == "DichVu" || router.name == "SessionDichVu") {
      DataCardItems.value.disableService = true; // disable select chọn dịch vụ
    }
    await getAllTableConfig(tableConfigResponse.result?.service_id);
    tempDataTableColumsConfig.value = response.result.table.format;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

watch(
  () => props.typeTableScreen,
  async (newData) => {
    typeTable.value = newData;
    await storeListService.getAllListService(newData);
  }
);

watch(
  () => storeListService.data,
  async () => {
    await getAllService();
  }
);

watch(
  () => props.showCardItemConfig,
  async () => {
    if (
      props.typeShowCardItemConfig == "update" &&
      props.idCardItem &&
      props.showCardItemConfig
    )
      await getInfoCardItems(props.idCardItem);
  }
);

watch(
  () => lang_web.showLang,
  () => {
    DataForm.formatAlign = [
      {
        value: "left",
        label: LanguageService?.lang?.align_left ?? "align_left",
      },
      {
        value: "right",
        label: LanguageService?.lang?.align_right ?? "align_right",
      },
      {
        value: "center",
        label: LanguageService?.lang?.align_center ?? "align_center",
      },
      {
        value: "justify",
        label: LanguageService?.lang?.align_justify ?? "align_justify",
      },
    ];
    DataForm.typeColumns = [
      {
        value: "none",
        label: LanguageService?.lang?.none_type_columns ?? "none_type_columns",
      },
      {
        value: "rate",
        label: LanguageService?.lang?.rate_type_columns ?? "rate_type_columns",
      },
      {
        value: "progress",
        label:
          LanguageService?.lang?.progress_type_columns ??
          "progress_type_columns",
      },
    ];
    DataForm.visibleColumns = [
      {
        value: true,
        label: LanguageService?.lang?.unvisible_columns ?? "unvisible_columns",
      },
      {
        value: false,
        label: LanguageService?.lang?.visible_columns ?? "visible_columns",
      },
    ];
  }
);

onMounted(async () => {
  if (typeTable.value)
    await storeListService.getAllListService(typeTable.value);
  await getAllService();
  if (props.typeShowCardItemConfig == "update" && props.idCardItem)
    await getInfoCardItems(props.idCardItem);
  await getListIconsColumns();
});
</script>

<style scoped lang="scss">
.btn-add-config-table {
  height: auto;
  min-height: 260px;
  width: 80px;
  background-color: #f7b84b;
  border: 2px solid #fca50f;
  border-radius: 7px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.btn-add-config-table i {
  font-size: 30px;
  color: #fff;
}
.span-step {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #15152969;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-step {
  height: 2px;
  width: 100px;
  background-color: #151529;
}
.passStep {
  background-color: #67c23a;
}
.line {
  height: 1px;
  background: #15152969;
  width: 160px;
  margin: 16px -195px 0px;
}
span.el-radio-button__inner {
  min-width: 200px;
}
:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: #f1f1f1;
}

.select-card-item {
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
}
.select-card-item {
  &__select {
    display: flex;
    justify-content: space-between;
  }
  &__select-item {
    width: 100%;
    margin: 0 3px;
  }
}
.chart-group span {
  color: var(--el-button-text-color, var(--el-text-color-regular)) !important;
  font-size: 13px;
}
:deep .chart-group .el-radio-button__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;
}
:deep .chart-group .el-radio-button {
  border: none;
}
:deep .el-radio-button__original-radio:hover + .el-radio-button__inner {
  background-color: rgba(41, 156, 219, 0.1);
  border-color: var(
    --el-radio-button-checked-border-color,
    var(--el-color-primary)
  );
}
:deep .el-radio-button__original-radio:checked + .el-radio-button__inner {
  background-color: rgba(41, 156, 219, 0.1);
}
.el-form-item .el-form-item {
  margin-bottom: 18px;
}
:deep .table-format-cell .label-requied {
  position: relative;
  color: red;
}
:deep .table-format .el-input,
:deep .table-format .el-select {
  max-width: 325px;
}
:deep .table-format .el-form-item__content {
  text-align: end;
  position: relative;
}
.table-format .label-requied {
  position: absolute;
  color: red;
  top: 0;
  left: -10px;
}
:deep .el-input.is-disabled .el-input__inner {
  color: var(--el-input-text-color, var(--el-text-color-regular));
  -webkit-text-fill-color: var(
    --el-input-text-color,
    var(--el-text-color-regular)
  );
}
:deep .el-input.is-disabled .el-input__wrapper {
  background-color: #f5f7fa;
  box-shadow: 0 0 0 1px #e4e7ed;
}
.render-table {
  overflow: auto;
  max-width: 690px;
  display: flex;
}
.px-style {
  background-color: #f1f1f1;
  border: thin solid #dcdcdc;
  margin-left: 5px;
  border-radius: 3px;
  height: 32px;
  line-height: 100%;
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep .table-format .el-input-number {
  width: 291px !important;
}
.optionCardItem__body .el-input-number {
  width: 100%;
}
.optionCardItem__header {
  display: flex;
  align-items: center;
}
.optionCardItem__header i {
  color: #878787;
  font-size: 16px;
  margin-right: 8px;
}
:deep .table-format .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
:deep .disabledLabel .el-form-item__label {
  color: #bcbec4;
}
.switch-icon-columns {
  margin-right: 7px;
}
.icon-columns {
  margin: 0 15px;
}
.icon-columns img {
  height: 24px;
  width: 24px;
}
.label-upload-icons {
  background-color: #f7b84b;
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  padding: 0 8px;
  cursor: pointer;
  transition: 0.2s;
}
.disableUploadIcon {
  cursor: no-drop;
  background-color: #b4b4b4;
}
.label-upload-icons:hover {
  opacity: 0.8;
}
.label-upload-icons img {
  margin-right: 7px;
}
.legend-options,
.title-opitons {
  border: thin dashed #dcdcdc;
  border-radius: 5px;
  padding: 5px 5px 10px 5px;
  margin-bottom: 20px;
  p {
    font-weight: 600;
  }
  &__items {
    display: flex;
  }
  &__item:nth-last-child(2) {
    margin: 0 30px;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
.title-options__title {
  margin-bottom: 15px !important;
}
:deep .grid-options {
  border: thin dashed #dcdcdc;
  border-radius: 5px;
  padding: 5px 5px 10px 5px;
  margin-bottom: 20px;
  p {
    font-weight: 600;
  }
  &__input {
    margin-right: 10px;
  }
  &__items {
    display: flex;
  }
  &__item {
    width: 100%;
    .el-form-item {
      margin-bottom: 0 !important;
      font-weight: 500;
    }
  }
}
:deep .sort-default {
  display: flex;
}
:deep .select-icons .el-input {
  width: 100px !important;
}
.card-item {
  &__block {
    display: flex;
    width: calc(50% - 10px);
    float: left;
    border: thin solid #dcdcdc;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: rgba(41, 156, 219, 0.1);
      // border-color: #409eff;
    }
  }
  &__status {
    position: absolute;
    top: 26%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
  }
  &__box {
    width: calc(100% - 70px);
    text-align: end;
  }
  &__title {
    color: #606266;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
.active {
  background-color: rgba(41, 156, 219, 0.1);
  border-color: #409eff;
}
.table-link > .el-form-item__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.error {
  color: var(--el-color-danger);
  font-size: 12px;
}
:deep .error_value {
  display: flex;
  .el-textarea__inner,
  .el-input__wrapper {
    box-shadow: 0 0 0 1px #f56c6c inset;
  }
}
:deep .el-tag {
  .el-tag__content {
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
